import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  selectUnmarked() {
    let selection = window.getSelection();
    selection.removeAllRanges();

    let rows = this.element.querySelectorAll("tr:not(.marked)");

    rows.forEach((row, index) => {
      let eligibleCells = row.querySelectorAll("td:not(.actions)");

      let firstCell = eligibleCells[0];
      let lastCell = eligibleCells[eligibleCells.length - 1];

      let range = document.createRange();
      range.setStart(firstCell.firstChild, 0);
      range.setEnd(lastCell.firstChild, (lastCell.firstChild as Text).length);
      selection.addRange(range);
    });

    document.execCommand("copy");
  }
}
